import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Container, Typography, Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@mui/material';
import { Tag } from "@pagopa/mui-italia";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import moment from "moment";

const ModaleSegnalazionePrivate = ({ segnalazione }) => {
    var dataAperturaTicket=moment(segnalazione.dataora_creazione);
    return (
        <>
            <img src="https://www.lagazzettadelmezzogiorno.it/resizer/-1/-1/true/2022_03_27/barb-1648409540915.jpg--.jpg" alt="Immagine segnalazione" className="w-100" />
            <Container style={{marginTop:'15px'}}>
                {segnalazione.status=="aperto" && <Tag color="success" value="APERTO" variant="undefined" />}
                <Typography variant="h4">{segnalazione.oggetto}</Typography>
                <Typography variant="body1">{segnalazione.descrizione}</Typography>
                <Typography variant="h6">{segnalazione.data}</Typography>
                <Timeline style={{paddingLeft:0,paddingRight:0}} sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
          paddingLeft: '0',
          paddingRight: '8px'
        },
      }}>
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        {dataAperturaTicket.format("DD/MM/YYYY HH:mm")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                            <SupportOutlinedIcon />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            Apertura ticket
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                        >
                        {dataAperturaTicket.add(56, 'minutes').format("DD/MM/YYYY HH:mm")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                            <AssignmentIndOutlinedIcon />
                        </TimelineDot>
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            Presa in carico segnalazione
                        </Typography>
                        <Typography variant="body1" component="div">
                            Da AMIU
                        </Typography>
                        </TimelineContent>
                    </TimelineItem>
                    </Timeline>
            </Container>
        </>
    );
}

export default ModaleSegnalazionePrivate;