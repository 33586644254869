import { Container } from "@mui/material";
import React from "react";
import { HeaderProduct, Hero } from "@pagopa/mui-italia";
import { Card, CardMedia, CardContent, Typography, CardActions, Button, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useAccount } from "../contexts/AccountContext";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Homepage = () => {
    const { account } = useAccount();
    return (
        <>
            <HeaderProduct productsList={[{"title":"Bentornato "+account.nome+"! ☀️"}]} borderBottom={0} />
            <Container maxWidth="false">
            <Card>
                <CardMedia
                    sx={{ height: 140 }}
                    image="https://statics.cedscdn.it/photos/MED/65/49/8256549_22202403_rifiuti_conferimento_bari_multe_controlli_agenti_cosa_cambia.jpg"
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    Dei rifiuti in strada
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    Un esempio di segnalazione
                    </Typography>
                </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default Homepage;