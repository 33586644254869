import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Snackbar, Fab, Stack, Chip, Container, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slide, List, ListItemButton, ListItemText,  ListItemIcon, Divider, ListItemSecondaryAction } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Tag } from "@pagopa/mui-italia";
import FormNuovaSegnalazione from '../components/forms/FormNuovaSegnalazione';

const MessaggiPage = () => {     
    const theme = useTheme();  
    const [tabAperta, setTabAperta] = useState('daleggere');
    const [segnalazioni, setSegnalazioni] = useState([]);

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/comments')
            .then(response => response.json())
            .then(data => {
                setSegnalazioni(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <>
        <Container maxWidth="false">
            <Stack style={{marginTop:'15px', position:'sticky', top:'48px',zIndex:999, background:'white', paddingTop:'5px', paddingBottom:'5px'}} spacing={1} direction="row">
                <Chip color="primary" label="Da leggere" variant={tabAperta=="daleggere" ? "filled" : "outlined"} onClick={() => setTabAperta("daleggere")} />
                <Chip color="primary" label="Letti" variant={tabAperta=="letti" ? "filled" : "outlined"} onClick={() => setTabAperta("letti")} />
            </Stack>
            <div style={{ display: tabAperta === 'daleggere' ? 'block' : 'none', marginTop:'10px' }}>
                <List>
                    <>
                        <ListItemButton>
                            <ListItemText primary="BariTiAscolta" secondary={"La tua segnalazione è stata presa in carico da AMIU"} />
                            <ListItemSecondaryAction style={{position:"unset",textAlign:"end",lineHeight:1}}>
                                <Typography variant="caption" style={{fontWeight:"bold"}}>26/07/2024<br />17:09</Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider component="div" />
                    </>
                </List>
            </div>
            <div style={{ display: tabAperta === 'letti' ? 'block' : 'none', marginTop:'10px' }}>
                <List>
                    {segnalazioni.filter(segnalazione => segnalazione.id % 2 === 0).map((segnalazione, index) => (
                    <>
                        <ListItemButton key={index}>
                            <ListItemText primary="BariTiAscolta" secondary={segnalazione.name} />
                            <ListItemSecondaryAction style={{position:"unset"}}>
                                <Typography variant="caption" style={{fontWeight:"bold"}}>10:50</Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider component="div" />
                    </>
                    ))}
                </List>
            </div>
        </Container>
        </>
    );
}

export default MessaggiPage;