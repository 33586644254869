import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Snackbar, Fab, Stack, Chip, Container, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slide, List, ListItemButton, ListItemText,  ListItemIcon, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Tag } from "@pagopa/mui-italia";
import FormNuovaSegnalazione from '../components/forms/FormNuovaSegnalazione';
import ModaleSegnalazionePrivate from "../components/modals/ModaleSegnalazionePrivate";
import { API_URL } from '../config';

const TueSegnalazioniPage = () => {     
    const theme = useTheme();  
    const [tabAperta, setTabAperta] = useState('aperte');
    const [openNuovaSegnalazione, setOpenNuovaSegnalazione] = useState(false);
    const [segnalazioni, setSegnalazioni] = useState([]);
    const [openMessageSegnalazione, setOpenMessageSegnalazione] = useState(false);
    const [openSegnalazione, setOpenSegnalazione] = useState(false);
    const [segnalazioneSelezionata, setSegnalazioneSelezionata] = useState(null);

    useEffect(() => {
        fetch(API_URL+'mieSegnalazioni/', {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          })
            .then(response => response.json())
            .then(data => {
                setSegnalazioni(data.segnalazioni);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <>
        <Container maxWidth="false">
            <Stack style={{marginTop:'15px', position:'sticky', top:'48px',zIndex:999, background:'white', paddingTop:'5px', paddingBottom:'5px'}} spacing={1} direction="row">
                <Chip color="primary" label="Aperte" variant={tabAperta=="aperte" ? "filled" : "outlined"} onClick={() => setTabAperta("aperte")} />
                <Chip color="primary" label="Chiuse" variant={tabAperta=="chiuse" ? "filled" : "outlined"} onClick={() => setTabAperta("chiuse")} />
            </Stack>
            <div style={{ display: tabAperta === 'aperte' ? 'block' : 'none', marginTop:'10px' }}>
                <List>
                    {segnalazioni.filter(segnalazione => segnalazione.status!=="chiuso").map((segnalazione, index) => (
                        <>
                            <ListItemButton onClick={() => {setSegnalazioneSelezionata(segnalazione);setOpenSegnalazione(true);}} key={index}>
                            <ListItemIcon>
                                <Typography className="font-monospace" variant="h6" component="div">#{segnalazione.id}</Typography>
                            </ListItemIcon>
                                <ListItemText primary={segnalazione.oggetto} secondary={<Tag color="success" value={segnalazione.etichetta} variant="undefined" />} />
                            </ListItemButton>
                            <Divider component="div" />
                        </>
                    ))}
                </List>
            </div>
            <div style={{ display: tabAperta === 'chiuse' ? 'block' : 'none', marginTop:'10px' }}>
                <List>
                    {segnalazioni.filter(segnalazione => segnalazione.status=="chiuso").map((segnalazione, index) => (
                        <>
                            <ListItemButton onClick={() => {setSegnalazioneSelezionata(segnalazione);setOpenSegnalazione(true);}} key={index}>
                            <ListItemIcon>
                                <Typography className="font-monospace" variant="h6" component="div">#{segnalazione.id}</Typography>
                            </ListItemIcon>
                                <ListItemText primary={segnalazione.oggetto} secondary={<Tag color="success" value={segnalazione.etichetta} variant="undefined" />} />
                            </ListItemButton>
                            <Divider component="div" />
                        </>
                    ))}
                </List>
            </div>
            <div style={{position: 'fixed', right: '20px', bottom: '80px', zIndex:99999}}>
                <Fab variant="extended" color="primary" sx={{pr:'24px', py:2}} onClick={() => setOpenNuovaSegnalazione(true)}>
                    <AddIcon sx={{ mr: 1 }} />
                    Nuova
                </Fab>
            </div>
        </Container>
        <Dialog
            fullScreen
            disableEscapeKeyDown
            open={openNuovaSegnalazione}
            onClose={() => setOpenNuovaSegnalazione(false)}
            sx={{ zIndex: 99999 }}
        >
            <AppBar sx={{ position: 'sticky', top:0 }}>
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenNuovaSegnalazione(false)}
                aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1, color:'white' }} variant="h6" component="div">
                Nuova segnalazione
                </Typography>
            </Toolbar>
            </AppBar>
            <Container maxWidth="false"><FormNuovaSegnalazione setOpenMessageSegnalazione={setOpenMessageSegnalazione} /></Container>
        </Dialog>
        <Dialog
              fullScreen
              disableEscapeKeyDown
              open={openSegnalazione}
              onClose={() => setOpenSegnalazione(false)}
              sx={{ zIndex: 99999 }}
          >
              <AppBar sx={{ position: 'sticky', top:0 }}>
              <Toolbar>
                  <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpenSegnalazione(false)}
                  aria-label="close"
                  >
                  <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1, color:'white' }} variant="h6" component="div">
                  Segnalazione
                  </Typography>
              </Toolbar>
              </AppBar>
              <ModaleSegnalazionePrivate segnalazione={segnalazioneSelezionata} />
          </Dialog>
        <Snackbar
            open={openMessageSegnalazione}
            autoHideDuration={6000}
            onClose={() => setOpenMessageSegnalazione(false)}
            message="Segnalazione inviata con successo"
        />
        </>
    );
}

export default TueSegnalazioniPage;