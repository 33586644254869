import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from '../config';

const AccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState({loggato: false, utente: null});
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const ChangeLogged = () => {
    aggiornaAccount();
    return null;
  };

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`${API_URL}account/`, requestOptions);
        const accountData = await response.json();
        if (accountData.loggato) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setAccount(accountData.utente);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      } finally {
        setLoading(false);
    }
    };

    fetchAccountInfo();
  }, []);

 const aggiornaAccount = () => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`${API_URL}account/`, requestOptions);
        const accountData = await response.json();
        if (accountData.loggato) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        setAccount(accountData.utente);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };
    setTimeout(() => {
      fetchAccountInfo();
    }, 1000);
  };

  const value = {
    account, loading, isAuthenticated
  };

  return (
    <AccountContext.Provider value={{account, loading, isAuthenticated, ChangeLogged}}>
      {children}
    </AccountContext.Provider>
  );
};