import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import RecyclingIcon from '@mui/icons-material/Recycling';
import { API_URL } from '../config';
import ModaleSegnalazionePublic from '../components/modals/ModaleSegnalazionePublic';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SegnalazioniPage = () => {
    const [located, setLocated] = useState(false);
    const [segnalazioni, setSegnalazioni] = useState([]);
    const [openSegnalazione, setOpenSegnalazione] = useState(false);
    const [segnalazioneSelezionata, setSegnalazioneSelezionata] = useState(null);

    useEffect(() => {
        fetch(API_URL+'tutteSegnalazioni/')
            .then(response => response.json())
            .then(data => {
                setSegnalazioni(data.segnalazioni);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);
    function LocationMarker({ located, setLocated}) {
        const map = useMap();
      
        useEffect(() => {
          if (!located) {
            map.locate();
            setLocated(true);
          }
        }, [map, located]);
      
        map.on('locationfound', (e) => {
          map.stopLocate();
          //map.flyTo(e.latlng, 13);
        });
      
        return null;
      }

      const createClusterCustomIcon = function (cluster) {
        return L.divIcon({
          html: `<div><span class="font-sans">${cluster.getChildCount()}</span></div>`,
          className: "marker-cluster custom-marker-cluster marker-cluster-large",
          iconSize: L.point(33, 33, true)
        })
      }    
      
    return (
        <>
            <MapContainer className="w-full h-abc" center={[41.13724901775989, 16.823130510930604]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                maxZoom={20}
                />
                <LocationMarker located={located} setLocated={setLocated} />
                <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
                {segnalazioni && segnalazioni.map((segnalazione, index) => (
                    <Marker key={index} position={[segnalazione["latitudine"], segnalazione["longitudine"]]} title={""} icon={L.divIcon({ html: `<span class="font-sans" style="font-size:30px"><span class="material-icons">recycling</span></span>`, className: "bg-transparent border-0 text-center flex items-center justify-center leading-none", iconSize: new L.Point(40, 47) })} eventHandlers={{
                    click: () => {
                        setSegnalazioneSelezionata(segnalazione);
                        setOpenSegnalazione(true);
                    }
                    }}></Marker>
                ))}
                </MarkerClusterGroup>
            </MapContainer>
            <Dialog
              fullScreen
              disableEscapeKeyDown
              open={openSegnalazione}
              onClose={() => setOpenSegnalazione(false)}
              sx={{ zIndex: 99999 }}
          >
              <AppBar sx={{ position: 'sticky', top:0 }}>
              <Toolbar>
                  <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpenSegnalazione(false)}
                  aria-label="close"
                  >
                  <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1, color:'white' }} variant="h6" component="div">
                  Segnalazione
                  </Typography>
              </Toolbar>
              </AppBar>
              <ModaleSegnalazionePublic segnalazione={segnalazioneSelezionata} />
          </Dialog>
        </>
    );
}

export default SegnalazioniPage;