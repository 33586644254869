import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import './fonts/titillium_pro/stylesheet.css';
import '@fontsource/roboto-mono';
import '@fontsource/lora';
import { CircularProgress, ThemeProvider, Container } from "@mui/material";
import { theme } from "@pagopa/mui-italia";
import Header from './components/website/header';
import './styles/app.css';
import "@fontsource/material-icons";
import Footer from './components/website/footer';
import Homepage from './pages/Homepage';
import SegnalazioniPage from './pages/SegnalazioniPage';
import TueSegnalazioniPage from './pages/TueSegnalazioniPage';
import PrivateRoute from './components/auth/PrivateRoute';
import LoginPage from './pages/LoginPage';
import { useAccount } from './contexts/AccountContext';
import MessaggiPage from './pages/MessaggiPage';

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};

const App = () => {
    const { isAuthenticated, loading } = useAccount();

    return (
            <ThemeProvider theme={theme}>
                {loading &&  <Container maxWidth="sm" style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh'}}><CircularProgress /></Container>}
                {(!loading && isAuthenticated) &&
                <Router>
                    <Header />
                    <Routes>
                        <Route path="/" element={<><Homepage /><ScrollToTop /></>} />
                        <Route path="/messaggi" element={<><MessaggiPage /><ScrollToTop /></>} />
                        <Route path="/segnalazioni" element={<><SegnalazioniPage /><ScrollToTop /></>} />
                        <Route path="/tue-segnalazioni" element={<><TueSegnalazioniPage /><ScrollToTop /></>} />

                    </Routes>
                    <Footer />
                </Router>
                }
                {(!loading && !isAuthenticated) &&
                <Router>
                    <Routes>
                        <Route path="/*" element={<><LoginPage /><ScrollToTop /></>} />
                    </Routes>
                </Router>
                }
            </ThemeProvider>
    );
};

export default App;