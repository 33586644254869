import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Container, Typography, Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@mui/material';
import { Tag } from "@pagopa/mui-italia";

const ModaleSegnalazionePublic = ({ segnalazione }) => {
    return (
        <>
            <img src="https://www.lagazzettadelmezzogiorno.it/resizer/-1/-1/true/2022_03_27/barb-1648409540915.jpg--.jpg" alt="Immagine segnalazione" className="w-100" />
            <Container style={{marginTop:'15px'}}>
                    {segnalazione.status=="aperto" && <Tag color="success" value="APERTO" variant="undefined" />}
                    <Typography variant="h4">{segnalazione.oggetto}</Typography>
                    <Typography variant="body1">{segnalazione.descrizione}</Typography>
                    <Typography variant="h6">{segnalazione.data}</Typography>
            </Container>
        </>
    );
}

export default ModaleSegnalazionePublic;