import React from 'react';
import { HeaderAccount } from "@pagopa/mui-italia";
import { BottomNav, BottomNavItem } from 'design-react-kit';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const navigate = useNavigate();
    var aa= {
        label: "baRIsolve"
    };
    return (
        <>
            {pathname !== '/segnalazioni' && <div style={{"height":"110px"}}></div>}
            <BottomNav>
                <BottomNavItem
                    iconName="it-pa"
                    label="home"
                    active={pathname === '/'}
                    onClick={() => {navigate('/')}}
                />
                <BottomNavItem
                    iconName="it-comment"
                    label="messaggi"
                    active={pathname === '/messaggi'}
                    onClick={() => {navigate('/messaggi')}}
                />
                <BottomNavItem
                    iconName="it-camera"
                    label="segnalazioni"
                    active={pathname === '/segnalazioni'}
                    onClick={() => {navigate('/segnalazioni')}}
                />
                <BottomNavItem
                    iconName="it-piattaforme"
                    label="tue segnalazioni"
                    active={pathname === '/tue-segnalazioni'}
                    onClick={() => {navigate('/tue-segnalazioni')}}
                />
                <BottomNavItem
                    iconName="it-user"
                    label="account"
                    active={pathname === '/account'}
                    onClick={() => {navigate('/account')}}
                />
            </BottomNav>
        </>
    );
}

export default Footer;