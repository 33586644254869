import React from 'react';
import { HeaderAccount } from "@pagopa/mui-italia";

const Header = () => {
    var aa= {
        label: "BariTiAscolta"
    };
    return (
        <div className="sticky-top"><HeaderAccount rootLink={aa} loggedUser={false} enableLogin={false} enableAssistanceButton={false} /></div>
    );
}

export default Header;