import React from 'react';
import { Divider, Typography, Box, Button, Stack, TextField, Grid, FormControl, InputLabel, MenuItem, Select, ListSubheader } from '@mui/material';

const FormNuovaSegnalazione = ({setOpenMessageSegnalazione}) => {

    const inviaSegnalazione = () => {
        fetch('https://baritiascolta.ottomanelliandrea.it/api/nuovaSegnalazione/')
            .then(response => response.json())
            .then(data => {
                setOpenMessageSegnalazione(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    return (
        <Box component="form">
            <Stack spacing={2} style={{marginTop:'15px'}}>
                <TextField variant="outlined" label="Oggetto" placeholder="Inserisci l'oggetto" fullWidth={true} />
                <TextField variant="outlined" label="Descrizione" placeholder="Descrivi il problema" fullWidth={true} multiline maxRows={6} />
                <FormControl>
                    <InputLabel htmlFor="grouped-select">Categoria</InputLabel>
                    <Select defaultValue="" id="grouped-select" label="Grouping" native>
                        <optgroup label="Rifiuti">
                            <option value="volvo">Rifiuti lasciati per strada</option>
                        </optgroup>
                        <optgroup label="AMTAB">
                            <option value="mercedes">Un autista non sa guidare</option>
                            <option value="f">Fumai ha fatto bloccare il pullman</option>
                        </optgroup>
                    </Select>
                </FormControl>
                <Divider component="div" />
                <Typography variant="button" display="block" gutterBottom>INVIA FOTO O ALLEGATI</Typography>
                <Divider component="div" />
                <Typography variant="button" display="block" gutterBottom>INDIRIZZO</Typography>
                <TextField variant="outlined" label="Indirizzo" placeholder="Inserisci l'indirizzo completo" fullWidth={true} multiline maxRows={2} />
            </Stack>
            <div style={{position:'fixed', bottom:'10px', left:'24px', right:'24px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button size="medium" variant="contained" onClick={inviaSegnalazione} fullWidth>INVIA</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="medium" color="error" variant="outlined" fullWidth>CANCELLA</Button>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

export default FormNuovaSegnalazione;